import React from "react";
import classNames from "classnames";
import {
  Container,
  Button,
  ButtonAlt,
  Text,
  AtForm,
  Image,
  Icon,
  AnimateIn,
} from "@atoms";
import { Share, MembershipLevelsOptions } from "@molecules";
import { m } from "framer-motion";

import loadable from "@loadable/component";

const Video = loadable(() => import("../atoms/Video"));

const ActionSteps = ({
  actionStepVariants,
  layout,
  lgca,
  heading,
  actionState,
  levels,
  issue,
  formid,
  activistCodes,
  actionDispatch,
  actionType,
  amounts,
  visibleAmounts,
  videoUrl,
  meta,
  alternateAction,
  alternateActionCta,
  supporterCta,
  impactCta,
  cta,
  placeholder,
  supporterSection,
  matterSection,
  signOn,
  url,
  lakota,
  copy,
  showReadMore,
  readMore,
  romero,
  form,
  setShowReadMore,
  readMoreCta,
  mobileCta,
}) => {
  return (
    <m.div
      variants={actionStepVariants}
      animate={actionStepVariants[actionState.status]}
      className="w-1/2"
    >
      <Container>
        <div className="flex flex-wrap justify-start gap-12 pb-12 md:flex-nowrap lg:gap-16">
          <div
            className={`w-full md:w-1/2 
          ${layout === "left" ? "md:order-2" : "md:order-1"} 
          ${levels ? "lg:w-3/5" : ""}`}
          >
            <AnimateIn>
              <div>
                {!lgca && (
                  <Text variant="h3" className={classNames("text-white", {})}>
                    {heading}
                  </Text>
                )}
                {signOn && (
                  <>
                    <Text className="font-extended mb-6 block text-4xl leading-tight text-black">
                      {heading}
                    </Text>
                    {meta.description && (
                      <Text className="font-sans my-6 leading-tight text-black">
                        {meta.description}
                      </Text>
                    )}
                    <div className="mt-6 flex items-center">
                      <Share color="blue" dark={signOn} small url={url} />
                    </div>
                  </>
                )}
                {lgca && !signOn && (
                  <>
                    <Text className="font-extended text-2xl leading-tight text-white sm:text-3xl">
                      {heading}
                    </Text>
                    {meta.description && (
                      <Text className="font-sans my-6 leading-tight text-white">
                        {meta.description}
                      </Text>
                    )}
                    <div className="mt-6 flex items-center">
                      {/* <Share color="blue" small url={url} /> */}
                    </div>
                  </>
                )}
                {(videoUrl || placeholder) && (
                  <div className="py-6 lg:py-8">
                    {videoUrl && videoUrl.length > 3 && (
                      <Video
                        url={videoUrl}
                        placeholder={placeholder}
                        // autoplayA
                        // muted
                        // play={
                        //   actionState.status !== "submitted" &&
                        //   actionState.status !== "completed"
                        // }
                      />
                    )}
                    {(!videoUrl || videoUrl.length < 3) && (
                      <Image image={placeholder} />
                    )}
                  </div>
                )}
                <div
                  style={{
                    textShadow: `0 0 40px ${
                      ((levels && levels.length) || lgca) && !signOn
                        ? "rgba(0,0,0,.5)"
                        : "rgba(255,255,255,.5)"
                    }`,
                  }}
                >
                  <Text
                    variant={lakota || signOn ? "body--large-tight" : "body"}
                    className={`${
                      ((levels && levels.length) || lgca) && !signOn
                        ? "text-white"
                        : "text-black"
                    } max-w-2xl leading-normal`}
                  >
                    {copy}
                  </Text>
                  {signOn && (
                    <div>
                      <div className="mt-6 text-black">
                        <ButtonAlt
                          className="mr-6"
                          onClick={() => {
                            matterSection.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                          noIcon
                        >
                          {impactCta}
                        </ButtonAlt>
                        <ButtonAlt
                          className="mr-6"
                          onClick={() => {
                            supporterSection.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                          noIcon
                        >
                          {supporterCta}
                        </ButtonAlt>
                      </div>
                      {/* <div className="mt-8">
                        <Text variant="h6">Share</Text>
                        <Text variant="body" className="my-3">
                          Invite others to sign on.
                        </Text>
                        <div className="-ml-2 mt-2">
                          <Share cta={false} small url={url} dark />
                        </div>
                      </div> */}
                    </div>
                  )}
                  <div>
                    {levels && levels.length && (
                      <div>
                        <MembershipLevelsOptions
                          levels={levels}
                          actionState={actionState}
                          actionDispatch={actionDispatch}
                        />
                      </div>
                    )}
                  </div>
                  {alternateAction && (
                    <div className="mt-3 overflow-hidden">
                      <ButtonAlt
                        to={alternateAction}
                        noIcon={!romero}
                        color={romero ? "gold" : "red"}
                      >
                        {alternateActionCta || "International Activists"}
                      </ButtonAlt>
                    </div>
                  )}
                  {readMore && readMore.length > 3 && (
                    <div className={classNames({ "text-white": lgca })}>
                      <div className="mt-3 overflow-hidden">
                        <ButtonAlt
                          onClick={() => setShowReadMore(s => !s)}
                          noIcon={!romero}
                          color={romero ? "gold" : "red"}
                        >
                          {readMoreCta || "Read More"}
                        </ButtonAlt>
                      </div>
                      <m.div
                        className="mt-3 overflow-hidden"
                        initial={{ height: 0, pointerEvents: "none" }}
                        animate={
                          showReadMore
                            ? { height: "auto", pointerEvents: "auto" }
                            : { height: 0, pointerEvents: "none" }
                        }
                      >
                        <Text
                          variant={lakota ? "body--large-tight" : "body"}
                          className={classNames("max-w-2xl leading-normal", {
                            "text-black": lakota,
                            "text-white": lgca,
                          })}
                        >
                          {readMore}
                        </Text>
                      </m.div>
                    </div>
                  )}
                </div>
                <div className="sticky bottom-0 left-0 right-0 mt-4 flex flex-col items-center justify-center py-3 md:hidden">
                  <Button
                    size="sm"
                    rounded={romero}
                    color={romero ? "blue" : "red"}
                    onClick={() => {
                      if (form.current) {
                        form.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }}
                  >
                    {mobileCta || cta || "Take Action"}
                  </Button>
                  <button
                    type="button"
                    onClick={() => {
                      if (form.current) {
                        form.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }}
                  >
                    {lakota && (
                      <Icon
                        name="arrow"
                        className="mt-3 h-8 w-8 rotate-90 transform text-red"
                      />
                    )}
                    {lgca && (
                      <Icon
                        name="lgcaArrow"
                        className="mt-3 h-8 w-8 rotate-90 transform text-red"
                      />
                    )}
                    {romero && (
                      <Icon name="chevron" className="mt-3 h-8 w-8 text-blue" />
                    )}
                  </button>
                </div>
              </div>
            </AnimateIn>
          </div>
          <div
            className={`mx-auto w-full max-w-md md:w-1/2 md:max-w-none
          ${layout === "left" ? "md:order-1" : "md:order-2"} 
          ${levels ? "mt-6 lg:w-2/5" : ""}`}
          >
            <div ref={form} className="sticky top-0 -mx-5 xxs:-mx-5 sm:mx-0">
              <AtForm
                formId={formid}
                activistCodes={activistCodes}
                actionState={actionState}
                actionDispatch={actionDispatch}
                actionType={actionType}
                hasLevels={levels?.length}
              />
            </div>
          </div>
        </div>
        {/* <div
          className={classNames(
            "mb-4 flex w-full items-center justify-center",
            { "text-white": (lgca || !!levels) && !signOn }
          )}
        >
          {!issue && !levels && (
            <ButtonAlt to="/" reverse>
              Go to Campaigns
            </ButtonAlt>
          )}
          {issue && issue.url && (
            <ButtonAlt to={issue.url} reverse>
              Go to {issue.title} Campaign
            </ButtonAlt>
          )}
        </div> */}
      </Container>
      <div className="h-12" />
    </m.div>
  );
};

export default ActionSteps;
